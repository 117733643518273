<template>
  <ion-page class="page scrollable bg-transparent pb-5" :key="$route.params.slug">
    <Breadcrumbs :breadcrumbs="ocsReadyBreadcrumbs" />
    <h1>{{ fandomData.name }}</h1>
    <p v-if="fandomData?.blurb">{{ fandomData.blurb }}</p>
    <div class="mt-3">
      <router-link :to="{ name: 'original-character-art' }">
        <ion-button color="medium" size="small" class="action-btn"> See More OC Art </ion-button>
      </router-link>
      <a v-for="f in moreFandoms" :href="`./${f.slug}`">
        <ion-button color="medium" size="small" class="action-btn"> {{ f.name }} </ion-button>
      </a>
    </div>
    <section class="mt-5">
      <!-- <div class="d-flex justify-content-between">
        <TitleWithAction title="Featured Original Character Art and Designs" action class="mb-3" />
        <router-link :to="{ name: 'original-character-art' }">
          <ion-button color="primary" class="action-btn" fill="clear"> See More OC Art </ion-button>
        </router-link>
      </div> -->
      <div class="text mt-n1" v-if="fandomData?.details">
        <div v-html="fandomData.details"></div>
      </div>
    </section>
    <section class="mt-5" v-if="originalCharacters?.length">
      <h2>
        Fan-made {{ fandomData.name }} Characters (OCs) <small>({{ characterCount }})</small>
      </h2>
      <InfiniteCharacters
        class="mt-2"
        :characters="originalCharacters"
        :loading="false"
        :paging="{}"
        :listLayout="true"
        :hideTypeTag="true"
        :showCharacterTags="true"
      />
    </section>
    <section class="mt-5" v-if="fanarts?.length">
      <h2>{{ fandomData.name }} Fan Art</h2>
      <div class="mx-auto w-100">
        <FanArtGrid :fan-arts="fanarts" />
      </div>
    </section>
    <section class="mt-5" v-if="communityPosts?.length">
      <h2>Community Posts</h2>
      <AllPosts :posts="communityPosts" :key="fandomData?.name + 'ps'" />
    </section>
    <ClientOnly>
      <section class="mt-5" v-if="socialSpaces?.length">
        <horizontal-spaces
          :key="fandomData?.name + 'hs'"
          id="spcs"
          :is-loading="isLoadingSpaces"
          :member-counts="spacesMemberCounts"
          :social-spaces="socialSpaces"
          :room-counts="spacesRoomCounts"
          @reaction="onSpaceReaction"
        />
      </section>
    </ClientOnly>
    <section class="mt-5">
      <div class="d-flex justify-content-between align-items-center">
        <h2>Fan Favorites</h2>
        <small class="text-black clickable-item-hov" @click="openModal">Submit a suggestion!</small>
      </div>
      <div class="text-center">
        <p class="text-gray">No submissions yet.<br />Send over your favorite links!</p>
      </div>
    </section>
    <ion-modal :is-open="isModalOpen" @didDismiss="closeModal">
      <ion-header>
        <ion-toolbar>
          <ion-title>Submit a Suggestion</ion-title>
          <ion-buttons slot="end">
            <ion-button @click="closeModal">Close</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding">
        <ion-item>
          <ion-label position="floating">URL</ion-label>
          <ion-input v-model="suggestionUrl" type="url" placeholder="Enter URL"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="floating">Note</ion-label>
          <ion-textarea v-model="suggestionNote" placeholder="Leave a note (optional)"></ion-textarea>
        </ion-item>
        <ion-button expand="block" @click="submitSuggestion" class="ion-margin-top">Submit</ion-button>
      </ion-content>
    </ion-modal>
  </ion-page>
</template>
<script lang="ts" setup>
import { toast } from '@/shared/native/toast';
import { SocialSpace } from '@/shared/types/static-types';
import Breadcrumbs from '@/shared/components/breadcrumbs.vue';
import InfiniteCharacters from '@/shared/components/InfiniteCharacters.vue';
import AllPosts from '@/shared/components/Posts/AllPosts.vue';
import FanArtGrid from '@/shared/components/fan-art-grid.vue';
import image from '../../../public/images/header_OCs.png';
import { getWorldsMemberCounts } from '@/shared/actions/worlds';
import { useDocument } from '@/composables/useDocument';
import { getFandom, submitFandomFavorite } from '@/shared/actions/public';
import { authStore } from '@/shared/pinia-store/auth';
const {
  public: { baseCDNUrl },
} = useRuntimeConfig();
const { currentRoute } = useRouter();
const route = useRoute();
const title = ref('');
const isLoadingSpaces = ref(false);
const isModalOpen = ref(false);
const suggestionNote = ref('');
const suggestionUrl = ref('');
const spacesMemberCounts = ref({});
const spacesRoomCounts = ref({});
const openModal = () => {
  const { isAuthenticated } = authStore();
  if (!isAuthenticated.value) return toast.show('Please log in to submit a suggestion.', 'nonative', 'primary');
  isModalOpen.value = true;
};
const closeModal = () => {
  isModalOpen.value = false;
};
const originalCharacters = computed(() => {
  return fandomData.value?.ocs || [];
});
const fanarts = computed(() => {
  const name = fandomData.value?.name;
  const imgs = [...fandomData.value.images];
  const out = imgs.map((x: any) => {
    x.character = {
      slug: x.public_location?.slug,
      info: {
        name: x.character_name,
      },
    };
    x.image = { description: x.description || x.character_name, username: x.username, image: x.visual_url };
    x.alt = `${name} art by @${x.username}`;
    return x;
  });
  return out || [];
});
const socialSpaces = computed(() => {
  return fandomData.value?.social_spaces || [];
});
const communityPosts = computed(() => {
  return fandomData.value?.community_posts || [];
});
const moreFandoms = computed(() => {
  return fandomData.value?.more_fandoms || [];
});
const fanFavorites = computed(() => {
  return fandomData.value?.fan_favorites || [];
});
const characterCount = computed(() => {
  return fandomData.value?.oc_count || 0;
});
// const fandomData = ref<any>({});
const fandomData = useState<any>('fandomData', () => ({}));
const ocsBreadcrumbs = ref([
  {
    label: 'Home',
    href: '/',
  },
  {
    label: 'Fandoms',
    href: '/fandoms',
    isActive: false,
  },
]);
const onSpaceReaction = (event: { reactResp: {}; social: SocialSpace }) => {
  return;
};

const submitSuggestion = async () => {
  const { slug } = route.params;
  try {
    await submitFandomFavorite(slug as string, suggestionUrl.value, suggestionNote.value);
    await toast.show('Thank you. Your submission will be reviewed!', 'nonative', 'primary');
    closeModal();
  } catch (e) {
    await toast.show('There was an error processing your submission.', 'nonative', 'danger');
  }
};
const ocsReadyBreadcrumbs = computed(() => {
  return [
    ...ocsBreadcrumbs.value,
    { label: fandomData.value.name, href: `/fandoms/${route.params.slug}`, isActive: true },
  ];
});
const picture = ref(`${baseCDNUrl}${image}`);
const description = ref(
  `Discover thousands of original characters! Original characters (OCs) are characters created by an author or artist! Some characters are completely original and may be in the creators' stories or comics. Other characters, known as fan characters, may be based on or exist in the universes of existing media.`
);
const allowCrawlers = ref(true);
const allowAiBots = ref(false);
const url = ref(`https://characterhub.com${route.path}`);

const fetchFandomData = async () => {
  const { slug } = route.params;
  fandomData.value = await getFandom(slug as string);
  const name = fandomData.value.name;
  title.value = `${name} | CharacterHub`;
  useChHead(title, title, description, url, picture, allowCrawlers, allowAiBots);
  return true;
};

useChAsyncData(fetchFandomData);

watch(currentRoute, fetchFandomData);

onMounted(async () => {
  const document = useDocument();
  if (!document.value) return;
  document.value.getElementById('app-nav-bar')?.scrollIntoView({ behavior: 'smooth' });
  // now fetch social space info which is client-side only, lazy loaded
  if (socialSpaces.value?.length) {
    spacesMemberCounts.value = {};
    const resp = await getWorldsMemberCounts(map(socialSpaces.value, 'id') as string[]);
    spacesMemberCounts.value = keyBy(resp, 'world_id');
  }
});
</script>
<style lang="sass" scoped>
.borders
  border-radius: 12px
.spacer
  border-bottom: 3px solid #AE38E5
h1
  font-size: 2rem
  font-weight: bold
  text-align: center
h2
  font-weight: bold
  font-size: 1.5rem
.page
  ::v-deep
    h3
      font-size: 16px !important
      font-weight: bold !important
    p
      margin: 10px 0
article
  background: rgba(121, 121, 121, 0.2)
  padding: 1rem
  border-radius: 10px
.title
  font-size: 28px
  font-weight: bold
  margin-bottom: 3rem
  text-align: center
.fandom
  border-radius: 16px
  background: rgba(121, 121, 121, 0.2)
  padding: 0.4rem 1rem
  font-size: 0.8rem
  min-width: 100px
  text-align: center
.fanart
  object-fit: cover
  width: 100%
  height: 100%
.fan-art-card
  border-radius: 10px
  height: 300px
.art-link
  font-weight: bold
.art-caption
  text-align: center
small
  font-weight: normal
</style>
